var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": _vm.maxWidth },
      model: {
        value: _vm.showDialog,
        callback: function($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "pa-4" },
        [
          _c(
            "v-card-text",
            { staticClass: "red--text font-weight-black pl-0 pr-0 mx-auto" },
            [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.$t("description.importantCheckNotes.explanation")
                  ) +
                  "\n    "
              )
            ]
          ),
          _c(
            "v-card-text",
            { staticClass: "font-weight-black pl-0 pr-0 mx-auto" },
            [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.$t("description.importantCheckNotes.fixExplanation")
                  ) +
                  "\n    "
              )
            ]
          ),
          _c(
            "v-card-text",
            { staticClass: "font-weight-black pl-0 pr-0 mx-auto" },
            [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.$t("description.importantCheckNotes.contactExplanation")
                  ) +
                  "\n    "
              )
            ]
          ),
          _c(
            "v-card-actions",
            { staticClass: "justify-center" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "title font-weight-black mr-4",
                  staticStyle: { "font-size": "15px !important" },
                  attrs: {
                    rounded: "",
                    color: "back",
                    width: "30vw",
                    "max-width": "120px",
                    dark: ""
                  },
                  on: { click: _vm.onClickNegativeButton }
                },
                [_vm._v(_vm._s(_vm.$t("button.return")))]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "title font-weight-black",
                  staticStyle: { "font-size": "12px !important" },
                  attrs: {
                    disabled: _vm.disabled,
                    rounded: "",
                    color: "next",
                    width: "30vw",
                    "max-width": "120px",
                    dark: ""
                  },
                  on: { click: _vm.onClickPositiveButton }
                },
                [
                  _c(
                    "v-container",
                    { staticClass: "py-0" },
                    [
                      _c("v-row", [
                        _c("span", { staticClass: "text-center mx-auto" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("button.importantCheckNext1")) +
                              "\n            "
                          )
                        ])
                      ]),
                      _c("v-row", { staticClass: "mx-auto text-center" }, [
                        _c("span", { staticClass: "text-center mx-auto" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("button.importantCheckNext2")) +
                              "\n            "
                          )
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }