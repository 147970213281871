<template>
  <v-dialog v-model="showDialog" persistent :max-width="maxWidth">
    <v-card class="pa-3">
      <v-card-text v-if="title" class="font-weight-black text-center">{{
        title
      }}</v-card-text>
      <template v-if="clauseFlg">
        <!-- 約款の場合 -->
        <ValidationForms ref="clauseForms">
          <v-card-text
            class="font-weight-black pb-0"
            v-for="(message, index) in splitMessageLine(
              $t('description.fixDialog.clauseExample')
            )"
            :key="'message' + index"
            aligin="left"
          >
            {{ message }}
          </v-card-text>
          <v-container class="pa-0 my-3">
            <v-row
              style="width: 90%; max-width: 350px;"
              class="mx-auto pb-1 pt-3"
            >
              <ValidationForm :rules="rules">
                <v-textarea
                  :label="$t('label.amendments')"
                  v-if="fixes[0]"
                  height="20vh"
                  :value="fixes[0].text"
                  v-model="fixes[0].text"
                  :counter="counter"
                  no-resize
                  clearable
                />
              </ValidationForm>
            </v-row>
          </v-container>
        </ValidationForms>
      </template>
      <template v-else>
        <!-- 約款以外の場合 -->
        <v-card-text
          class="font-weight-black pb-0 "
          v-for="(message, index) in splitMessageLine(
            $t('description.fixDialog.example')
          )"
          :key="'message' + index"
          aligin="left"
        >
          {{ message }}
        </v-card-text>
        <v-container
          class="pa-0 mb-0"
          v-for="(fix, index) in fixes"
          :key="'fix' + index"
        >
          <v-row class="ma-0 pb-1 pt-3 pl-0 pr-4">
            <v-col class="pa-0 ma-0" cols="2"> （{{ index + 1 }}） </v-col>
            <v-col class="pa-0 ma-0" cols="10">
              <!-- プラン名の選択 -->
              <v-row v-if="planList.length > 0" class="pb-1">
                <v-select
                  v-model="fix.planId"
                  :label="$t('label.planName')"
                  style="font-size:12px"
                  item-text="name"
                  item-value="id"
                  :items="planList"
                  :value="fix.planId"
                  hide-details
                  clearable
                />
              </v-row>
              <v-row
                v-if="fix.planId != null || planList.length == 0"
                class="pb-1"
              >
                <!-- 修正項目の選択 -->
                <v-select
                  v-model="fix.modificationItemId"
                  :label="$t('label.modificationItem')"
                  style="font-size:12px"
                  item-text="name"
                  item-value="id"
                  :items="list"
                  :value="fix.modificationItemId"
                  hide-details
                  clearable
                />
              </v-row>
              <v-row
                v-if="
                  fix.modificationItemId != null &&
                    (planList.length == 0 || fix.planId != null)
                "
                class="pb-1"
              >
                <!-- 修正内容の入力 -->
                <ValidationForms ref="textForms" style="width:100%">
                  <ValidationForm :rules="rules">
                    <v-textarea
                      :label="$t('label.amendments')"
                      class="pa-0"
                      style="font-size:12px; line-height:1.25rem;"
                      :value="fix.text"
                      v-model="fix.text"
                      rows="3"
                      row-height="15"
                      height="90"
                      no-resize
                      clearable
                      :counter="counter"
                    />
                  </ValidationForm>
                </ValidationForms>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <v-card-actions class="justify-center pb-5">
        <template v-if="negativeButtonTitle !== null">
          <v-btn
            rounded
            class="title font-weight-black mr-4"
            color="back"
            width="30vw"
            max-width="120px"
            style="font-size:15px !important"
            dark
            @click="onClickNegativeButton"
            >{{ negativeButtonTitle }}</v-btn
          >
        </template>
        <v-btn
          :disabled="disabled"
          rounded
          class="title font-weight-black"
          color="next"
          width="30vw"
          max-width="120px"
          style="font-size:15px !important"
          dark
          @click="beforePositiveButton(fixes)"
          >{{ positiveButtonTitle }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { splitMessageLine } from '@/lib/commonUtil';
import ValidationForms from '@/components/organisms/common/ValidationForms';
import ValidationForm from '@/components/organisms/common/ValidationForm';

export default {
  name: 'FixDialog',
  props: {
    maxWidth: Number,
    title: String,
    parentFixes: Array,
    text: String,
    list: Array,
    planList: Array,
    rules: String,
    counter: Number,
    negativeButtonTitle: String,
    positiveButtonTitle: String,
    onClickNegativeButton: Function,
    onClickPositiveButton: Function,
    showDialog: Boolean,
    disabled: Boolean,
    importantFlg: Boolean,
    clauseFlg: Boolean,
  },
  components: {
    ValidationForms,
    ValidationForm,
  },
  data: () => ({
    fixes: [],
  }),
  methods: {
    // メッセージを改行で表示
    splitMessageLine(message) {
      return splitMessageLine(message);
    },
    async beforePositiveButton(fixes) {
      // 全ての項目の入力バリデーションエラー有無を取り出し、判定を行う
      const allResult = this.$refs['textForms']
        ? [
            this.$refs['textForms'][0]
              ? await this.$refs['textForms'][0].validate()
              : null,
            this.$refs['textForms'][1]
              ? await this.$refs['textForms'][1].validate()
              : null,
            this.$refs['textForms'][2]
              ? await this.$refs['textForms'][2].validate()
              : null,
          ]
        : [];
      // 約款修正入力のバリデーションエラー確認
      allResult.push(
        this.$refs['clauseForms']
          ? await this.$refs['clauseForms'].validate()
          : null
      );

      const isValidationError = allResult.includes(false);
      if (!isValidationError) {
        this.onClickPositiveButton(fixes);

        if (this.$refs.clauseForms) {
          this.$refs.clauseForms.reset();
        }
        if (this.$refs.textForms) {
          this.$refs.textForms.forEach(form => form.reset());
        }
      }
    },
  },
  watch: {
    parentFixes: {
      immediate: true,
      handler: function(params) {
        this.fixes = params;
      },
    },
  },
};
</script>
<style>
.v-textarea textarea {
  line-height: 1.25rem;
  padding-top: 4px;
}
</style>
