<template>
  <v-dialog v-model="showDialog" persistent :max-width="maxWidth">
    <v-card>
      <v-row v-if="!isFinalSelectDialog" class="mr-0">
        <v-col cols="8">
          <v-card-title
            v-if="title !== null"
            class="headline font-weight-bold py-0"
          >
            {{ title }}
          </v-card-title>
        </v-col>
        <v-col cols="4">
          <v-btn
            rounded
            class="font-weight-black float-right"
            color="back"
            width="5vw"
            max-width="170px"
            style="font-size:15px !important"
            dark
            @click="onClickNegativeButton"
          >
            {{ negativeButtonTitle }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-0">
          <v-card-subtitle v-if="subTitle !== null" class="py-2">{{
            subTitle
          }}</v-card-subtitle>
        </v-col>
      </v-row>
      <v-row v-if="plans !== null" class="ml-0 mr-0">
        <v-col>
          <v-card>
            <v-list>
              <template v-for="(plan, index) in plans">
                <v-list-item
                  two-line
                  :key="plan.planName"
                  @click="onClickPositiveButton(plan)"
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ plan.planName }}</v-list-item-title>
                    <v-list-item-subtitle>{{
                      plan.comment
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider
                  v-if="index != plans.length - 1"
                  :inset="inset"
                  :key="index"
                ></v-divider>
              </template>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
      <v-card-text
        v-if="text !== null"
        :class="isFinalSelectDialog ? textCenterStyle : textLeftStyle"
        ><p
          v-for="(message, index) in splitMessageLine(text)"
          :key="index"
          class="my-0"
        >
          {{ message }}
        </p></v-card-text
      >
      <v-card-text
        v-if="subText !== null"
        class="font-weight-black text-center"
        >{{ subText }}</v-card-text
      >
      <template v-if="!isFinalSelectDialog">
        <template v-if="positiveButtonTitle !== null">
          <v-card-actions class="justify-center pb-5">
            <v-btn
              v-if="positiveButtonTitle !== null"
              rounded
              class="title font-weight-black"
              color="next"
              width="50vw"
              max-width="200px"
              style="font-size:15px !important"
              dark
              @click="onClickPositiveButton(planDetail)"
              >{{ positiveButtonTitle }}</v-btn
            >
          </v-card-actions>
        </template>
      </template>
      <template v-if="isFinalSelectDialog">
        <v-card-actions class="justify-center pb-5">
          <v-btn
            rounded
            class="title font-weight-black mr-4"
            color="back"
            width="30vw"
            max-width="120px"
            style="font-size:15px !important"
            dark
            @click="onClickNegativeButton"
            >{{ negativeButtonTitle }}</v-btn
          >
          <v-btn
            :disabled="disabled"
            rounded
            class="title font-weight-black"
            color="next"
            width="30vw"
            max-width="120px"
            style="font-size:15px !important"
            dark
            @click="onClickPositiveButton()"
            >{{ positiveButtonTitle }}</v-btn
          >
        </v-card-actions>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import { splitMessageLine } from '@/lib/commonUtil';

export default {
  name: 'SelectPlanDialog',
  props: {
    maxWidth: Number,
    title: String,
    subTitle: String,
    text: String,
    subText: String,
    plans: Array[Object],
    plan: String,
    planDetail: Object,
    negativeButtonTitle: String,
    positiveButtonTitle: String,
    onClickNegativeButton: Function,
    onClickPositiveButton: Function,
    showDialog: Boolean,
    disabled: Boolean,
    isFinalSelectDialog: Boolean,
  },
  data() {
    return {
      inset: false,
      textCenterStyle: ['font-weight-black', 'text-center', 'pt-5'],
      textLeftStyle: ['font-weight-black', 'text-left', 'pt-5'],
    };
  },
  methods: {
    splitMessageLine(message) {
      return splitMessageLine(message);
    },
  },
};
</script>
