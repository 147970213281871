var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": _vm.maxWidth },
      model: {
        value: _vm.showDialog,
        callback: function($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "pa-3" },
        [
          _vm.title
            ? _c(
                "v-card-text",
                { staticClass: "font-weight-black text-center" },
                [_vm._v(_vm._s(_vm.title))]
              )
            : _vm._e(),
          _vm.clauseFlg
            ? [
                _c(
                  "ValidationForms",
                  { ref: "clauseForms" },
                  [
                    _vm._l(
                      _vm.splitMessageLine(
                        _vm.$t("description.fixDialog.clauseExample")
                      ),
                      function(message, index) {
                        return _c(
                          "v-card-text",
                          {
                            key: "message" + index,
                            staticClass: "font-weight-black pb-0",
                            attrs: { aligin: "left" }
                          },
                          [
                            _vm._v(
                              "\n          " + _vm._s(message) + "\n        "
                            )
                          ]
                        )
                      }
                    ),
                    _c(
                      "v-container",
                      { staticClass: "pa-0 my-3" },
                      [
                        _c(
                          "v-row",
                          {
                            staticClass: "mx-auto pb-1 pt-3",
                            staticStyle: { width: "90%", "max-width": "350px" }
                          },
                          [
                            _c(
                              "ValidationForm",
                              { attrs: { rules: _vm.rules } },
                              [
                                _vm.fixes[0]
                                  ? _c("v-textarea", {
                                      attrs: {
                                        label: _vm.$t("label.amendments"),
                                        height: "20vh",
                                        value: _vm.fixes[0].text,
                                        counter: _vm.counter,
                                        "no-resize": "",
                                        clearable: ""
                                      },
                                      model: {
                                        value: _vm.fixes[0].text,
                                        callback: function($$v) {
                                          _vm.$set(_vm.fixes[0], "text", $$v)
                                        },
                                        expression: "fixes[0].text"
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  2
                )
              ]
            : [
                _vm._l(
                  _vm.splitMessageLine(_vm.$t("description.fixDialog.example")),
                  function(message, index) {
                    return _c(
                      "v-card-text",
                      {
                        key: "message" + index,
                        staticClass: "font-weight-black pb-0 ",
                        attrs: { aligin: "left" }
                      },
                      [_vm._v("\n        " + _vm._s(message) + "\n      ")]
                    )
                  }
                ),
                _vm._l(_vm.fixes, function(fix, index) {
                  return _c(
                    "v-container",
                    { key: "fix" + index, staticClass: "pa-0 mb-0" },
                    [
                      _c(
                        "v-row",
                        { staticClass: "ma-0 pb-1 pt-3 pl-0 pr-4" },
                        [
                          _c(
                            "v-col",
                            { staticClass: "pa-0 ma-0", attrs: { cols: "2" } },
                            [_vm._v(" （" + _vm._s(index + 1) + "） ")]
                          ),
                          _c(
                            "v-col",
                            { staticClass: "pa-0 ma-0", attrs: { cols: "10" } },
                            [
                              _vm.planList.length > 0
                                ? _c(
                                    "v-row",
                                    { staticClass: "pb-1" },
                                    [
                                      _c("v-select", {
                                        staticStyle: { "font-size": "12px" },
                                        attrs: {
                                          label: _vm.$t("label.planName"),
                                          "item-text": "name",
                                          "item-value": "id",
                                          items: _vm.planList,
                                          value: fix.planId,
                                          "hide-details": "",
                                          clearable: ""
                                        },
                                        model: {
                                          value: fix.planId,
                                          callback: function($$v) {
                                            _vm.$set(fix, "planId", $$v)
                                          },
                                          expression: "fix.planId"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              fix.planId != null || _vm.planList.length == 0
                                ? _c(
                                    "v-row",
                                    { staticClass: "pb-1" },
                                    [
                                      _c("v-select", {
                                        staticStyle: { "font-size": "12px" },
                                        attrs: {
                                          label: _vm.$t(
                                            "label.modificationItem"
                                          ),
                                          "item-text": "name",
                                          "item-value": "id",
                                          items: _vm.list,
                                          value: fix.modificationItemId,
                                          "hide-details": "",
                                          clearable: ""
                                        },
                                        model: {
                                          value: fix.modificationItemId,
                                          callback: function($$v) {
                                            _vm.$set(
                                              fix,
                                              "modificationItemId",
                                              $$v
                                            )
                                          },
                                          expression: "fix.modificationItemId"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              fix.modificationItemId != null &&
                              (_vm.planList.length == 0 || fix.planId != null)
                                ? _c(
                                    "v-row",
                                    { staticClass: "pb-1" },
                                    [
                                      _c(
                                        "ValidationForms",
                                        {
                                          ref: "textForms",
                                          refInFor: true,
                                          staticStyle: { width: "100%" }
                                        },
                                        [
                                          _c(
                                            "ValidationForm",
                                            { attrs: { rules: _vm.rules } },
                                            [
                                              _c("v-textarea", {
                                                staticClass: "pa-0",
                                                staticStyle: {
                                                  "font-size": "12px",
                                                  "line-height": "1.25rem"
                                                },
                                                attrs: {
                                                  label: _vm.$t(
                                                    "label.amendments"
                                                  ),
                                                  value: fix.text,
                                                  rows: "3",
                                                  "row-height": "15",
                                                  height: "90",
                                                  "no-resize": "",
                                                  clearable: "",
                                                  counter: _vm.counter
                                                },
                                                model: {
                                                  value: fix.text,
                                                  callback: function($$v) {
                                                    _vm.$set(fix, "text", $$v)
                                                  },
                                                  expression: "fix.text"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                })
              ],
          _c(
            "v-card-actions",
            { staticClass: "justify-center pb-5" },
            [
              _vm.negativeButtonTitle !== null
                ? [
                    _c(
                      "v-btn",
                      {
                        staticClass: "title font-weight-black mr-4",
                        staticStyle: { "font-size": "15px !important" },
                        attrs: {
                          rounded: "",
                          color: "back",
                          width: "30vw",
                          "max-width": "120px",
                          dark: ""
                        },
                        on: { click: _vm.onClickNegativeButton }
                      },
                      [_vm._v(_vm._s(_vm.negativeButtonTitle))]
                    )
                  ]
                : _vm._e(),
              _c(
                "v-btn",
                {
                  staticClass: "title font-weight-black",
                  staticStyle: { "font-size": "15px !important" },
                  attrs: {
                    disabled: _vm.disabled,
                    rounded: "",
                    color: "next",
                    width: "30vw",
                    "max-width": "120px",
                    dark: ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.beforePositiveButton(_vm.fixes)
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.positiveButtonTitle))]
              )
            ],
            2
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }