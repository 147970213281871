var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-layout",
        { attrs: { column: "", "fill-height": "", wrap: "" } },
        [
          _c(
            "v-container",
            {
              staticClass: "mx-auto",
              staticStyle: { width: "100%", overflow: "hidden" }
            },
            [
              _c(
                "v-row",
                { staticStyle: { width: "90%" } },
                [
                  _c("v-card-title", { staticClass: "font-weight-bold pb-3" }, [
                    _vm._v(
                      "\n          " + _vm._s(_vm.description) + "\n        "
                    )
                  ])
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "pl-3" },
                _vm._l(
                  _vm.splitMessageLine(_vm.applicationFormConfirmationMessage),
                  function(message, index) {
                    return _c("p", { key: index, staticClass: "my-1" }, [
                      _vm._v("\n          " + _vm._s(message) + "\n        ")
                    ])
                  }
                ),
                0
              ),
              _c(
                "v-row",
                { staticClass: "mb-12" },
                [
                  _c(
                    "v-container",
                    {
                      ref: "scrollTarget",
                      staticClass: "overflow-y-auto pa-0",
                      staticStyle: {
                        "max-width": "100%",
                        height: "50vh",
                        "-webkit-overflow-scrolling": "touch"
                      },
                      attrs: { id: "scroll-target", wrap: "" }
                    },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "mx-auto",
                          staticStyle: { width: "100%", height: "auto" }
                        },
                        [
                          _c("v-img", {
                            ref: "doc",
                            staticStyle: {
                              "max-width": "100%",
                              height: "auto"
                            },
                            attrs: { id: "image", src: _vm.imgSrc },
                            on: { load: _vm.onDocLoaded }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "pb-2" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "pa-0",
                      staticStyle: { "text-align": "center" },
                      attrs: { width: "30vw", "max-width": "170px" }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "font-weight-black mx-auto",
                          staticStyle: { "font-size": "15px" },
                          attrs: {
                            align: "center",
                            width: "30vw",
                            "max-width": "170px",
                            color: "next",
                            dark: "",
                            rounded: ""
                          },
                          on: { click: _vm.openConfirmationMessageDialog }
                        },
                        [_vm._v(_vm._s(_vm.$t("button.confirmationMessage")))]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "pa-0",
                      staticStyle: { "text-align": "center" },
                      attrs: { width: "30vw", "max-width": "170px" }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "font-weight-black mx-auto",
                          staticStyle: { "font-size": "15px" },
                          attrs: {
                            align: "center",
                            width: "30vw",
                            "max-width": "170px",
                            color: "next",
                            dark: _vm.isShowFix,
                            disabled: !_vm.isShowFix,
                            rounded: ""
                          },
                          on: { click: _vm.importantCheck }
                        },
                        [_vm._v(_vm._s(_vm.$t("button.withCorrections")))]
                      ),
                      _vm.hasFix()
                        ? _c("p", { staticClass: "ma-0" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("label.hasFix")) +
                                "\n          "
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                _vm._l(2, function(n) {
                  return _c(
                    "v-col",
                    {
                      key: n,
                      staticClass: "pa-0",
                      staticStyle: { "text-align": "center" },
                      attrs: { width: "30vw", "max-width": "170px" }
                    },
                    [
                      n == 1
                        ? [
                            _c(
                              "v-btn",
                              {
                                staticClass: "font-weight-black mx-auto",
                                staticStyle: { "font-size": "15px" },
                                attrs: {
                                  align: "center",
                                  width: "30vw",
                                  "max-width": "170px",
                                  color: "back",
                                  dark: "",
                                  rounded: ""
                                },
                                on: { click: _vm.onBackClicked }
                              },
                              [_vm._v(_vm._s(_vm.$t("button.back")))]
                            )
                          ]
                        : _vm._e(),
                      n == 2
                        ? [
                            _vm.choosePlan
                              ? [
                                  _vm.planChoices.length === 1
                                    ? [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass:
                                              "font-weight-black mx-auto",
                                            staticStyle: {
                                              "font-size": "15px"
                                            },
                                            attrs: {
                                              align: "center",
                                              width: "30vw",
                                              "max-width": "170px",
                                              color: "next",
                                              dark: "",
                                              rounded: ""
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.openPlansDialog()
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("button.reviewed")
                                              ) + "\n                "
                                            )
                                          ]
                                        )
                                      ]
                                    : [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass:
                                              "font-weight-black mx-auto",
                                            staticStyle: {
                                              "font-size": "15px"
                                            },
                                            attrs: {
                                              align: "center",
                                              width: "30vw",
                                              "max-width": "170px",
                                              color: "next",
                                              dark: "",
                                              rounded: ""
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.openPlansDialog()
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("button.selectPlan")
                                              ) + "\n                "
                                            )
                                          ]
                                        )
                                      ],
                                  _vm.selectPlanName
                                    ? [
                                        _c("p", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("label.selectedPlan")
                                            ) +
                                              "：" +
                                              _vm._s(_vm.selectPlanName)
                                          )
                                        ])
                                      ]
                                    : _vm._e()
                                ]
                              : [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "font-weight-black mx-auto",
                                      staticStyle: { "font-size": "15px" },
                                      attrs: {
                                        align: "center",
                                        width: "30vw",
                                        "max-width": "170px",
                                        color: "next",
                                        dark: _vm.isShowContract,
                                        disabled: !_vm.isShowContract,
                                        rounded: ""
                                      },
                                      on: { click: _vm.goNextPage }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("button.reviewed")))]
                                  )
                                ]
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                }),
                1
              ),
              _c("ImportantCheckDialog", {
                attrs: {
                  maxWidth: 320,
                  showDialog: _vm.showImportantCheckDialog,
                  onClickNegativeButton: _vm.closeImportantCheckDialog,
                  onClickPositiveButton: _vm.openFixDialog
                }
              }),
              _c("FixDialog", {
                attrs: {
                  maxWidth: 320,
                  showDialog: _vm.showFixDialog,
                  title: null,
                  parentFixes: _vm.fixes,
                  list: _vm.modificationItemList,
                  planList: _vm.planList,
                  rules: "max:" + _vm.maxLength,
                  counter: _vm.maxLength,
                  negativeButtonTitle: _vm.$t("button.back"),
                  positiveButtonTitle: _vm.$t("button.inputComplete"),
                  onClickNegativeButton: _vm.closeFixDialog,
                  onClickPositiveButton: _vm.sendFix,
                  importantFlg: _vm.importantFlag,
                  clauseFlg: _vm.clauseFlg
                }
              }),
              _c("Dialog", {
                attrs: {
                  maxWidth: 320,
                  showDialog: _vm.showDialog,
                  title: null,
                  text: _vm.$t("label.fixResultDialogSuccess"),
                  subText: null,
                  negativeButtonTitle: null,
                  positiveButtonTitle: _vm.$t("button.close"),
                  onClickNegativeButton: null,
                  onClickPositiveButton: _vm.closeDialog
                }
              }),
              _c("Dialog", {
                attrs: {
                  maxWidth: 320,
                  showDialog: _vm.showConfirmationMessageDialog,
                  title: null,
                  text: null,
                  multiLineBoldText: _vm.confirmationMessage,
                  subText: null,
                  negativeButtonTitle: null,
                  positiveButtonTitle: _vm.$t("button.close"),
                  onClickNegativeButton: null,
                  onClickPositiveButton: _vm.closeConfirmationMessageDialog
                }
              }),
              _c("SelectPlanDialog", {
                attrs: {
                  maxWidth: 320,
                  showDialog: _vm.showPlansDialog,
                  isFinalSelectDialog: false,
                  plans: _vm.planChoices,
                  plan: null,
                  planDetail: null,
                  title: _vm.$t("button.selectPlan"),
                  subTitle: _vm.planSelectionSubtext,
                  text: null,
                  subText: null,
                  negativeButtonTitle: _vm.$t("button.close"),
                  positiveButtonTitle: null,
                  onClickNegativeButton: _vm.closePlansDialog,
                  onClickPositiveButton: _vm.openPlanDetailDialog
                }
              }),
              _c("SelectPlanDialog", {
                attrs: {
                  maxWidth: 320,
                  showDialog: _vm.showPlanDetailDialog,
                  isFinalSelectDialog: false,
                  plans: null,
                  plan: null,
                  planDetail: _vm.planDetail,
                  title: _vm.planDetail.planName,
                  subTitle: null,
                  text: _vm.planDetail.comment,
                  subText: null,
                  negativeButtonTitle: _vm.$t("button.return"),
                  positiveButtonTitle: _vm.$t("button.selectThisPlan"),
                  onClickNegativeButton: _vm.closePlanDetailDialog,
                  onClickPositiveButton: _vm.openPlanDialog
                }
              }),
              _c("SelectPlanDialog", {
                attrs: {
                  maxWidth: 320,
                  showDialog: _vm.showSelectPlanDialog,
                  isFinalSelectDialog: true,
                  plans: null,
                  plan: _vm.nowSelectedPlan.planName,
                  planDetail: _vm.planDetail,
                  title: null,
                  subTitle: null,
                  text: _vm.planText,
                  subText: _vm.$t("description.selectPlanDialog.explanation2"),
                  negativeButtonTitle: _vm.$t("button.return"),
                  positiveButtonTitle: _vm.$t("button.reviewed"),
                  onClickNegativeButton: _vm.closeSelectPlanDialog,
                  onClickPositiveButton: _vm.selectPlan
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }