import store from '@/store';
import { getApplicationForm } from '@/apis/contractor/contracts';
import { getPdfImage } from '@/lib/commonUtil';

// 申込書の画像化
export const getApplicationFormImage = async () => {
  const blob = await getApplicationForm();
  const reader = await getPdf(blob);

  const b64 = reader.result;

  const images = await getPdfImage(b64);

  store.dispatch('contract/setApplicationForms', images);

  // PDFの取得
  function getPdf(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader);
      reader.onerror = e => reject(e);
      reader.readAsDataURL(blob);
    });
  }
};
