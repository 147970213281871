<template>
  <v-dialog v-model="showDialog" persistent :max-width="maxWidth">
    <v-card class="pa-4">
      <v-card-text class="red--text font-weight-black pl-0 pr-0 mx-auto">
        {{ $t('description.importantCheckNotes.explanation') }}
      </v-card-text>
      <v-card-text class="font-weight-black pl-0 pr-0 mx-auto">
        {{ $t('description.importantCheckNotes.fixExplanation') }}
      </v-card-text>
      <v-card-text class="font-weight-black pl-0 pr-0 mx-auto">
        {{ $t('description.importantCheckNotes.contactExplanation') }}
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn
          rounded
          class="title font-weight-black mr-4"
          color="back"
          width="30vw"
          max-width="120px"
          style="font-size:15px !important"
          dark
          @click="onClickNegativeButton"
          >{{ $t('button.return') }}</v-btn
        >
        <v-btn
          :disabled="disabled"
          rounded
          class="title font-weight-black"
          color="next"
          width="30vw"
          max-width="120px"
          style="font-size:12px !important"
          dark
          @click="onClickPositiveButton"
        >
          <v-container class="py-0">
            <v-row>
              <span class="text-center mx-auto">
                {{ $t('button.importantCheckNext1') }}
              </span>
            </v-row>
            <v-row class="mx-auto text-center">
              <span class="text-center mx-auto">
                {{ $t('button.importantCheckNext2') }}
              </span>
            </v-row>
          </v-container>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ImportantCheckDialog',
  props: {
    maxWidth: Number,
    onClickNegativeButton: Function,
    onClickPositiveButton: Function,
    showDialog: Boolean,
    disabled: Boolean,
  },
  data() {
    return {
      breakLineStyle: ['ma-0', 'px-2', 'py-3'],
      noBreakLineStyle: ['ma-0', 'px-2', 'py-0'],
    };
  },
  methods: {},
};
</script>
