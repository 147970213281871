<template>
  <v-container fluid>
    <v-layout column fill-height wrap>
      <v-container
        style="width: 100%; max-height=90vh; overflow: hidden; pl-0"
        class="mx-auto"
      >
        <v-row style="width: 90%">
          <v-card-title class="font-weight-bold pb-3">
            {{ description }}
          </v-card-title>
        </v-row>
        <v-row class="pl-3">
          <p
            v-for="(message, index) in splitMessageLine(
              applicationFormConfirmationMessage
            )"
            :key="index"
            class="my-1"
          >
            {{ message }}
          </p>
        </v-row>
        <v-row class="mb-12">
          <v-container
            ref="scrollTarget"
            id="scroll-target"
            class="overflow-y-auto pa-0"
            style="max-width: 100%; height: 50vh; -webkit-overflow-scrolling: touch;"
            wrap
          >
            <v-row class="mx-auto" style="width: 100%; height:auto;">
              <v-img
                id="image"
                :src="imgSrc"
                ref="doc"
                v-on:load="onDocLoaded"
                style="max-width: 100%; height:auto;"
              />
            </v-row>
          </v-container>
        </v-row>
        <v-row class="pb-2">
          <!-- 右寄せのためからの領域を作成 -->
          <v-col
            width="30vw"
            max-width="170px"
            class="pa-0"
            style="text-align:center"
          >
            <!-- 項目説明ボタン  -->
            <v-btn
              class="font-weight-black mx-auto"
              align="center"
              width="30vw"
              max-width="170px"
              color="next"
              style="font-size:15px"
              dark
              @click="openConfirmationMessageDialog"
              rounded
              >{{ $t('button.confirmationMessage') }}</v-btn
            >
          </v-col>

          <v-col
            width="30vw"
            max-width="170px"
            class="pa-0"
            style="text-align:center"
          >
            <!-- 修正ボタン  -->
            <v-btn
              class="font-weight-black mx-auto"
              align="center"
              width="30vw"
              max-width="170px"
              color="next"
              style="font-size:15px"
              :dark="isShowFix"
              :disabled="!isShowFix"
              @click="importantCheck"
              rounded
              >{{ $t('button.withCorrections') }}</v-btn
            >
            <p v-if="hasFix()" class="ma-0">
              {{ $t('label.hasFix') }}
            </p>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col
            v-for="n in 2"
            :key="n"
            width="30vw"
            max-width="170px"
            class="pa-0"
            style="text-align:center"
          >
            <template v-if="n == 1" class="pa-0">
              <!-- 戻るボタン -->
              <v-btn
                class="font-weight-black mx-auto"
                align="center"
                @click="onBackClicked"
                width="30vw"
                max-width="170px"
                color="back"
                style="font-size:15px"
                dark
                rounded
                >{{ $t('button.back') }}</v-btn
              >
            </template>
            <!-- 確認済みボタン -->
            <template v-if="n == 2" class="pa-0">
              <!-- プラン選択ボタン -->
              <template v-if="choosePlan">
                <template v-if="planChoices.length === 1">
                  <!-- プラン1つの場合 -->
                  <v-btn
                    class="font-weight-black mx-auto"
                    align="center"
                    width="30vw"
                    max-width="170px"
                    color="next"
                    style="font-size:15px"
                    dark
                    @click="openPlansDialog()"
                    rounded
                    >{{ $t('button.reviewed') }}
                  </v-btn>
                </template>
                <template v-else>
                  <!-- プラン複数の場合 -->
                  <v-btn
                    class="font-weight-black mx-auto"
                    align="center"
                    width="30vw"
                    max-width="170px"
                    color="next"
                    style="font-size:15px"
                    dark
                    @click="openPlansDialog()"
                    rounded
                    >{{ $t('button.selectPlan') }}
                  </v-btn>
                </template>
                <template v-if="selectPlanName">
                  <p>{{ $t('label.selectedPlan') }}：{{ selectPlanName }}</p>
                </template>
              </template>
              <template v-else>
                <!-- 修正あり時の確認ボタン -->
                <v-btn
                  class="font-weight-black mx-auto"
                  align="center"
                  width="30vw"
                  max-width="170px"
                  color="next"
                  style="font-size:15px"
                  :dark="isShowContract"
                  :disabled="!isShowContract"
                  rounded
                  @click="goNextPage"
                  >{{ $t('button.reviewed') }}</v-btn
                >
              </template>
            </template>
          </v-col>
        </v-row>
        <!-- 重要事項修正確認 -->
        <ImportantCheckDialog
          :maxWidth="320"
          :showDialog="showImportantCheckDialog"
          :onClickNegativeButton="closeImportantCheckDialog"
          :onClickPositiveButton="openFixDialog"
        />
        <FixDialog
          :maxWidth="320"
          :showDialog="showFixDialog"
          :title="null"
          :parentFixes="fixes"
          :list="modificationItemList"
          :planList="planList"
          :rules="`max:${maxLength}`"
          :counter="maxLength"
          :negativeButtonTitle="$t('button.back')"
          :positiveButtonTitle="$t('button.inputComplete')"
          :onClickNegativeButton="closeFixDialog"
          :onClickPositiveButton="sendFix"
          :importantFlg="importantFlag"
          :clauseFlg="clauseFlg"
        />
        <Dialog
          :maxWidth="320"
          :showDialog="showDialog"
          :title="null"
          :text="$t('label.fixResultDialogSuccess')"
          :subText="null"
          :negativeButtonTitle="null"
          :positiveButtonTitle="$t('button.close')"
          :onClickNegativeButton="null"
          :onClickPositiveButton="closeDialog"
        />
        <!-- 項目説明 -->
        <Dialog
          :maxWidth="320"
          :showDialog="showConfirmationMessageDialog"
          :title="null"
          :text="null"
          :multiLineBoldText="confirmationMessage"
          :subText="null"
          :negativeButtonTitle="null"
          :positiveButtonTitle="$t('button.close')"
          :onClickNegativeButton="null"
          :onClickPositiveButton="closeConfirmationMessageDialog"
        />
        <!-- プラン一覧 -->
        <SelectPlanDialog
          :maxWidth="320"
          :showDialog="showPlansDialog"
          :isFinalSelectDialog="false"
          :plans="planChoices"
          :plan="null"
          :planDetail="null"
          :title="$t('button.selectPlan')"
          :subTitle="planSelectionSubtext"
          :text="null"
          :subText="null"
          :negativeButtonTitle="$t('button.close')"
          :positiveButtonTitle="null"
          :onClickNegativeButton="closePlansDialog"
          :onClickPositiveButton="openPlanDetailDialog"
        />
        <!-- プラン詳細 -->
        <SelectPlanDialog
          :maxWidth="320"
          :showDialog="showPlanDetailDialog"
          :isFinalSelectDialog="false"
          :plans="null"
          :plan="null"
          :planDetail="planDetail"
          :title="planDetail.planName"
          :subTitle="null"
          :text="planDetail.comment"
          :subText="null"
          :negativeButtonTitle="$t('button.return')"
          :positiveButtonTitle="$t('button.selectThisPlan')"
          :onClickNegativeButton="closePlanDetailDialog"
          :onClickPositiveButton="openPlanDialog"
        />
        <!-- プラン選択確認 -->
        <SelectPlanDialog
          :maxWidth="320"
          :showDialog="showSelectPlanDialog"
          :isFinalSelectDialog="true"
          :plans="null"
          :plan="nowSelectedPlan.planName"
          :planDetail="planDetail"
          :title="null"
          :subTitle="null"
          :text="planText"
          :subText="$t('description.selectPlanDialog.explanation2')"
          :negativeButtonTitle="$t('button.return')"
          :positiveButtonTitle="$t('button.reviewed')"
          :onClickNegativeButton="closeSelectPlanDialog"
          :onClickPositiveButton="selectPlan"
        />
      </v-container>
    </v-layout>
  </v-container>
</template>
<script>
import { createCanvas } from 'canvas';
import Dialog from '../../components/organisms/contractor/Dialog.vue';
import FixDialog from '../../components/organisms/contractor/FixDialog.vue';
import ImportantCheckDialog from '../../components/organisms/contractor/ImportantCheckDialog.vue';
import SelectPlanDialog from '../../components/organisms/contractor/SelectPlanDialog.vue';
import { mapActions, mapGetters } from 'vuex';
import { getCoordinateManagement } from '@/apis/contractor/contracts';
import { getPlans } from '@/apis/contractor/plans';
import { getApplicationFormImage } from '@/lib/contractor/util.js';
import {
  applicationFormScrollTopMargin,
  MaxCount,
  ModificationItemOtherId,
  MaxLength,
} from '@/lib/const';
import { splitMessageLine } from '@/lib/commonUtil';

export default {
  props: {
    stepPoint: Number,
  },
  components: {
    FixDialog,
    Dialog,
    SelectPlanDialog,
    ImportantCheckDialog,
  },
  data: () => ({
    imgSrc: '',
    choosePlan: false,
    planChoices: [],
    showFixDialog: false,
    showDialog: false,
    showSelectPlanDialog: false,
    showPlansDialog: false,
    showPlanDetailDialog: false,
    showConfirmationMessageDialog: false,
    planDetail: {},
    nowSelectedPlan: { planName: '' },
    planText: '',
    scrollLength: 0,
    pageIndex: 1,
    fixes: [],
    coordinates: [],
    masterWidth: '',
    masterHeight: '',
    isShowFix: false,
    isShowContract: false,
    description: '',
    confirmationMessage: '',
    modificationItemList: [],
    planList: [],
    importantFlag: false,
    clauseFlg: false,
    importantFlg: false,
    defaultFix: {
      text: '',
      modificationItemId: null,
    },
    fixCount: 0,
    applicationFormConfirmationMessage: null,
    maxLength: 0,
    selectPlanName: null,
    showImportantCheckDialog: false,
    planSelectionSubtext: ''
  }),

  async mounted() {
    this.pageIndex = Number(this.$route.params.pageNumber);

    const response = await getPlans();
    if (response instanceof Error) {
      this.$router.replace('/Error');
      return;
    }
    if (response) {
      this.$set(this, 'planChoices', response);
    }

    const contract = await getCoordinateManagement();
    this.masterWidth = contract.applicationForm.width;
    this.masterHeight = contract.applicationForm.height;
    this.coordinates = contract.applicationForm.coordinateManagements;
    this.applicationFormConfirmationMessage =
      contract.applicationForm.confirmationMessage;
    this.maxLength = MaxLength.Default;

    this.setPageSum(this.coordinates[this.coordinates.length - 1].screenPage);

    const target = this.coordinates.find(
      ({ screenPage }) => screenPage === this.pageIndex
    );

    this.choosePlan = target.planFlg;
    this.description = target.description;
    this.confirmationMessage =
      this.planChoices.length === 1 || !target.multiplePlansConfirmation
        ? target.singlePlanConfirmation
        : target.multiplePlansConfirmation;
    this.importantFlag = Boolean(target.importantFlg);
    this.clauseFlg = Boolean(target.clauseFlg);

    this.planSelectionSubtext = this.planChoices.filter(planChoice => planChoice.comment).length > 0
      ? this.$t('description.selectPlanDialog.explanation3')
      : this.$t('') ; 

    this.fixCount = this.clauseFlg ? 1 : MaxCount.Fix;

    const targetFix = this.fix().find(k => k.index == this.pageIndex);

    this.$set(this, 'modificationItemList', target.modificationItems);
    if (this.modificationItemList.length > 0 || this.clauseFlg) {
      this.$set(this, 'isShowFix', true);
      this.modificationItemList.push({
        name: this.$t('label.other'),
        id: ModificationItemOtherId,
      });
    }

    if (this.choosePlan) {
      this.planList = this.planChoices.map(plan => ({
        name: plan.planName,
        id: plan.id,
      }));
    }
    // 選択中のプラン名取得
    if (this.selectedPlan())
      this.$set(
        this,
        'selectPlanName',
        this.selectedPlan().planName ? this.selectedPlan().planName : '-'
      );

    // スキップフラグが立っていて、かつそのページに修正が存在せず、かつプラン項目でない場合、スキップすべきか確認する
    if (target.skipFlg && !targetFix && !target.planFlg) {
      // これより前の項目の重要項目で修正があったか確認する
      const importants = this.coordinates.filter(
        k => k.screenPage < target.screenPage && k.importantFlg
      );
      for (let i = 0; i < importants.length; i++) {
        if (this.fix().find(k => k.index == importants[i].screenPage)) {
          this.onBackClicked();
          return;
        }
      }
    }

    // 修正あり
    if (this.fix().length > 0) {
      // 重要事項に修正があるか
      this.importantFlg = Boolean(this.fix().find(k => k.important == 1));
    }

    await this.createImage();

    this.$set(this, 'isShowContract', true);
  },
  methods: {
    ...mapGetters('contract', [
      'fix',
      'needUpload',
      'selectedPlan',
      'applicationForms',
      'pageSum',
      'pendingStep',
      'pendingApplicationFormsPage',
    ]),
    ...mapActions('contract', [
      'setFix',
      'setSelectedPlan',
      'setNeedUpload',
      'setPageSum',
      'setPendingStep',
      'setPendingApplicationFormsPage',
    ]),
    importantCheck() {
      if (this.importantFlag) {
        this.showImportantCheckDialog = true;
      } else {
        this.openFixDialog();
      }
    },
    closeImportantCheckDialog() {
      this.showImportantCheckDialog = false;
    },
    openFixDialog() {
      this.showImportantCheckDialog = false;
      let fixObj = this.fix().find(k => k.index == this.pageIndex);
      this.fixes = fixObj ? JSON.parse(JSON.stringify(fixObj.fix)) : [];
      while (this.fixes.length < this.fixCount) {
        this.fixes.push(JSON.parse(JSON.stringify(this.defaultFix)));
      }
      this.showFixDialog = true;
    },
    closeFixDialog() {
      this.showFixDialog = false;
    },
    hasFix() {
      return !!this.fix().find(k => k.index == this.pageIndex);
    },
    sendFix(fixes) {
      this.showFixDialog = false;
      this.showDialog = true;
      let allfixes = this.fix();
      if (!this.clauseFlg) {
        // 入力された項目のみ抽出
        fixes = fixes
          .filter(
            fix =>
              fix.text &&
              fix.modificationItemId != null &&
              (!this.choosePlan || fix.planId)
          )
          .map(fix => ({
            modificationItemId: fix.modificationItemId,
            text: fix.text,
            planId: fix.planId ? fix.planId : null,
            modificationItemName: this.modificationItemList
              .filter(
                modificationItem =>
                  modificationItem.id === fix.modificationItemId
              )
              .map(modificationItem => modificationItem.name)[0],
            planName: fix.planId
              ? this.planList
                  .filter(plan => plan.id === fix.planId)
                  .map(plan => plan.name)[0]
              : null,
          }));
      } else {
        if (!fixes[0].text) fixes = [];
      }

      allfixes = allfixes.filter(k => k.index != this.pageIndex);
      if (fixes.length > 0) {
        const coordinateData = this.coordinates.find(
          ({ screenPage }) => screenPage === this.pageIndex
        );
        allfixes.push({
          fix: fixes,
          index: this.pageIndex,
          coordinateManagementId: coordinateData.id,
          description: coordinateData.description,
          important: coordinateData.importantFlg,
        });
      }

      // 重要事項に修正があるか
      this.importantFlg = Boolean(allfixes.find(k => k.important == 1));

      this.setFix(allfixes);
    },
    openPlanDialog(plan) {
      this.planText =
        plan.planName + this.$t('description.selectPlanDialog.explanation1');
      this.nowSelectedPlan = plan;
      this.showSelectPlanDialog = true;
    },
    selectPlan() {
      this.setSelectedPlan(this.nowSelectedPlan);
      this.closeSelectPlanDialog();
      this.closePlanDetailDialog();
      this.closePlansDialog();
      this.goNextPage();
    },
    closeDialog() {
      this.showDialog = false;
    },
    closeSelectPlanDialog() {
      this.showSelectPlanDialog = false;
    },
    openPlansDialog() {
      if (this.planChoices.length === 1) {
        if (this.planChoices[0].comment) {
          this.openPlanDetailDialog(this.planChoices[0]);
        } else {
          this.openPlanDialog(this.planChoices[0]);
        }
      } else {
        this.showPlansDialog = true;
      }
    },
    closePlansDialog() {
      this.showPlansDialog = false;
    },
    openPlanDetailDialog(plan) {
      this.planDetail = plan;
      this.showPlanDetailDialog = true;
    },
    closePlanDetailDialog() {
      this.showPlanDetailDialog = false;
    },
    goNextPage() {
      this.$set(this, 'isShowContract', false);
      let nextPath;
      const nextPage = this.pageIndex + 1;
      if (nextPage > this.pageSum()) {
        let needUpload =
          this.selectedPlan().message && this.selectedPlan().message.length > 0;

        nextPath = needUpload
          ? '/check_need_upload#upload_required_documents'
          : '/check_need_upload#final_review_contract';
        this.setNeedUpload(needUpload);

        const nextStep = this.stepPoint + 1;
        // 操作内容によって遷移先が異なるため確認ステップを強制的に書き換える
        this.setPendingStep(nextStep);
      } else {
        const target = this.coordinates.find(
          ({ screenPage }) => screenPage === nextPage
        );
        const targetFix = this.fix().find(k => k.index == nextPage);

        // スキップフラグが立っていて、かつそのページに修正が存在せず、かつプラン項目でない場合、スキップすべきか確認する
        if (target.skipFlg && !targetFix && !target.planFlg) {
          // これより前の項目の重要項目で修正があったか確認する
          const importants = this.coordinates.filter(
            k => k.screenPage < target.screenPage && k.importantFlg
          );
          for (let i = 0; i < importants.length; i++) {
            // 現在の修正内容内に、このページより前の重要項目があった場合、次のページを確認する
            if (this.fix().find(k => k.index == importants[i].screenPage)) {
              this.pageIndex++;
              this.goNextPage();
              return;
            }
          }
        }
        if (this.pendingApplicationFormsPage() < nextPage)
          this.setPendingApplicationFormsPage(nextPage);
        nextPath = `/review_contract/${nextPage}`;
      }
      window.location.href = nextPath;
    },
    onDocLoaded() {
      setTimeout(() => {
        this.updateScrollLength();
        this.$refs.scrollTarget.scroll({ top: this.scrollLength });
      }, 100);
    },
    updateScrollLength() {
      this.pageIndex = Number(this.$route.params.pageNumber);
      let position = this.coordinates.find(
        ({ screenPage }) => screenPage === this.pageIndex
      ).yCoordinate;
      const scrollTargetHeight = document.getElementById('image').offsetHeight;

      this.scrollLength =
        (position * scrollTargetHeight) / this.masterHeight -
        applicationFormScrollTopMargin;
    },
    onBackClicked() {
      const backPageNumber = --this.$route.params.pageNumber;
      const backPath =
        backPageNumber > 0
          ? `/review_contract/${backPageNumber}`
          : '/explan_important_matters';
      window.location.href = backPath;
    },
    async createImage() {
      if (this.applicationForms().length === 0) await getApplicationFormImage();

      let imageCoordinate = this.coordinates.find(
        ({ screenPage }) => screenPage === this.pageIndex
      );
      //画像オブジェクトを生成
      let img = await getImage(
        this.applicationForms()[imageCoordinate.pdfPage]
      );

      const canvas = createCanvas(img.width, img.height);
      const highlightCanvas = createCanvas(img.width, img.height);
      const canvasContext = canvas.getContext('2d');
      const highlightCanvasContext = highlightCanvas.getContext('2d');

      // 申込書マスタのサイズに合わせてハイライト位置を特定
      const x = imageCoordinate.xCoordinate * (img.width / this.masterWidth);
      const y = imageCoordinate.yCoordinate * (img.height / this.masterHeight);
      const dx = imageCoordinate.width * (img.width / this.masterWidth);
      const dy = imageCoordinate.height * (img.height / this.masterHeight);
      // 切り抜き (クローズアップ)
      canvasContext.rect(x, y, dx, dy);
      canvasContext.fill();
      canvasContext.clip();

      canvasContext.drawImage(img, 0, 0, canvas.width, canvas.height);

      highlightCanvasContext.drawImage(
        img,
        0,
        0,
        highlightCanvas.width,
        highlightCanvas.height
      );
      highlightCanvasContext.fillStyle = 'rgba(0,0,0,0.5)';
      highlightCanvasContext.fillRect(
        0,
        0,
        highlightCanvas.width,
        highlightCanvas.height
      );
      const conCatCanvas = createCanvas(img.width, img.height);
      const conCatCanvasContext = conCatCanvas.getContext('2d');

      await concatCanvas(conCatCanvasContext, [
        highlightCanvasContext,
        canvasContext,
      ]);

      this.imgSrc = conCatCanvas.toDataURL();

      // 画像を読み込む
      function getImage(src) {
        return new Promise((resolve, reject) => {
          const image = new Image();
          image.onload = () => resolve(image);
          image.onerror = e => reject(e);
          image.src = src;
        });
      }
      // canvasを画像として読み込む
      function getImagefromCanvas(id) {
        return new Promise((resolve, reject) => {
          const image = new Image();
          image.onload = () => resolve(image);
          image.onerror = e => reject(e);
          image.src = id.canvas.toDataURL();
        });
      }
      // ハイライト画像を合成
      async function concatCanvas(base, asset) {
        for (let i = 0; i < asset.length; i++) {
          const image1 = await getImagefromCanvas(asset[i]);
          base.drawImage(image1, 0, 0, canvas.width, canvas.height);
        }
        const image = document.getElementById('image');
        image.src = base.canvas.toDataURL();
      }
    },
    // メッセージを改行で表示
    splitMessageLine(message) {
      return splitMessageLine(message);
    },
    openConfirmationMessageDialog() {
      this.showConfirmationMessageDialog = true;
    },
    closeConfirmationMessageDialog() {
      this.showConfirmationMessageDialog = false;
    },
  },
};
</script>
